import React, { Fragment, useEffect, useState, useMemo } from "react";
import { gql, useQuery } from "urql";
import { Link, useParams, useLocation, useNavigate } from "react-router-dom";
import { Dropdown } from "@fluentui/react";
import { useQueryState } from "../../../components/src/use-query-state";
import { sortBy, cloneDeep, keyBy } from "lodash";

import { priceFormat, twoDecPriceFormat } from "../../../common/src";
import { Svgs } from "../../../components/src";
import { DateForm } from "../../../components/src/dateForm";
import { exportToXlsx, sheetType } from "../../../components/src/common/exportToXlsx";
import { usePrevious, useQueryArgs } from "../../../components/src/common/Utils";

import { Page } from "../components/Page";
import { Instrument, TransactionFilterInput } from "../types.generated";

import { PermissionTypeEnum, PermissionAssetEnum } from "../types.generated";
import { userHaveAccessTo } from "../common/Permissions";

import { serializeSwedenDate } from "../components/dateFormater";
import { TableGrouper } from "../../../components/src/react-table/TableGrouper";
import { DownloadDigiaFileButton } from "./DownloadDigiaFileButton";

export const GET_DATA = gql`
    query getData($filter: PositionFilter) {
        me {
            roles {
                _id
                assets
                clientIds
                permissionType
                name
            }
        }

        tradingmanager(filterZeroPositions: true, filter: $filter, lookThrough: false) {
            instrumentId
            name
            longName
            isin
            quantity
            currency
            exposure
            localExposure
            valuationPrice
            valuationDate
            valuationAccruedInterest
            valuationCleanPrice
            valuationCurrency
            fxRate
            bloombergTicker
            isCashAccount
            accountName
            instrument {
                type
                creditRating
                modelType
                modelNotionalScaling
                productType
                countryOfListing
            }
            issuerName
            issuerId
            issuerLei
            issuerType
            issuerIndustryCode
        }
    }
`;

const GET_CLIENTS = gql`
    query {
        clients: parties(filter: { typeIn: [Fund, Client] }) {
            _id
            name
            accountingCurrency
            instruments {
                _id
                productType
            }
        }
    }
`;

const getNotional = (quantity, instrument) => {
    if (
        instrument &&
        (instrument.modelType === "Swap" || instrument.modelType === "Swaption") &&
        quantity > 0.9999999 &&
        quantity < 1.0000001
    )
        return instrument.modelNotionalScaling;
    else return quantity;
};

interface Props {
    showClientSelector?: boolean;
}

export const PositionsGroupedPage = ({ showClientSelector }: Props = { showClientSelector: true }): React.ReactElement => {
    const { clientDashName, tabId } = useParams();
    const previousClientDashName = usePrevious(clientDashName);
    const previousTabId = usePrevious(tabId);
    const location = useLocation();
    const navigate = useNavigate();
    const [date] = useQueryState("date", serializeSwedenDate(new Date(new Date().setDate(new Date().getDate() - 1))));
    const previousDate = usePrevious(date);
    const { queryArgs, pushQueryArgs } = useQueryArgs();

    const [selectedClient, setSelectedClient] = useState(null);
    const [filter, setFilter] = useState<TransactionFilterInput>(null);
    const previousSelectedClient = usePrevious(selectedClient);

    const [{ fetching: loadingClients, error: errorClients, data: dataClients }] = useQuery({
        query: GET_CLIENTS,
        requestPolicy: "cache-and-network"
    });
    const [{ fetching: loading, error, data }] = useQuery({
        query: GET_DATA,
        variables: { filter },
        pause: filter ? false : true,
        requestPolicy: "cache-and-network"
    });

    const processedData: { portfolioValue: number; allPositions: any[] } = useMemo(() => {
        if (selectedClient && data && data.tradingmanager && data.tradingmanager.length) {
            let selectedPartyInstruments: Record<string, Partial<Instrument>> = {};
            selectedPartyInstruments = keyBy(selectedClient.instruments, "_id");

            let portfolioValue = 0;
            let tradingManagerData = cloneDeep(data.tradingmanager);
            tradingManagerData = tradingManagerData.map((position) => {
                position.clientName = selectedClient.label;
                position.productType = position.instrument ? position.instrument.productType : null;

                portfolioValue += position.exposure;
                const { instrument, ...rest } = position;
                const notional = getNotional(rest.quantity, instrument);
                return { ...rest, ...instrument, notional };
            });

            const tradingManagerDataSorted = sortBy(tradingManagerData, "isCashAccount", "name");
            if (Object.keys(selectedPartyInstruments).length)
                tradingManagerDataSorted.forEach((instrument) => {
                    if (!instrument.modelType && selectedPartyInstruments[instrument.instrumentId]) {
                        instrument.modelType = selectedPartyInstruments[instrument.instrumentId].productType;
                    }
                });

            return { portfolioValue: portfolioValue, allPositions: tradingManagerDataSorted };
        } else {
            return null;
        }
    }, [data, selectedClient]);

    const columns = React.useMemo(
        () => [
            {
                header: "Client",
                accessorKey: "clientName"
            },
            {
                header: "Type",
                accessorKey: "modelType"
            },
            {
                header: "Name",
                accessorKey: "name",
                cell: (cellProps) => {
                    const { row } = cellProps;
                    if (row.original.isCashAccount) {
                        return (
                            <Link to={`/parties/${selectedClient._id}/instruments/${row.original.instrumentId}`} target={"_blank"}>
                                {cellProps.getValue()}
                            </Link>
                        );
                    } else {
                        return (
                            <Link to={`/instruments/${row.original.instrumentId}`} target={"_blank"}>
                                {cellProps.getValue()}
                            </Link>
                        );
                    }
                }
            },
            {
                header: "Long name",
                accessorKey: "longName"
            },
            {
                header: "ISIN",
                accessorKey: "isin",
                size: 90
            },
            {
                header: "Credit rating",
                accessorKey: "creditRating",
                size: 90
            },
            {
                header: "Price",
                accessorKey: "valuationPrice",
                cell: (cellProps) => <div style={{ textAlign: "right" }}>{priceFormat(cellProps.getValue())}</div>,
                aggregatedCell: () => null,
                size: 90
            },
            {
                header: "Quantity",
                accessorKey: "quantity",
                cell: (cellProps) => <div style={{ textAlign: "right" }}>{twoDecPriceFormat(cellProps.getValue())}</div>,
                aggregatedCell: () => null
            },
            {
                header: "Notional",
                accessorKey: "notional",
                cell: (cellProps) => <div style={{ textAlign: "right" }}>{twoDecPriceFormat(cellProps.getValue())}</div>,
                aggregatedCell: () => null
            },
            {
                header: "Value (SEK)",
                accessorKey: "exposure",
                cell: (cellProps) => <div style={{ textAlign: "right" }}>{twoDecPriceFormat(cellProps.getValue())}</div>,
                aggregationFn: "sum",
                aggregatedCell: (cellProps) => <div style={{ textAlign: "right" }}>{twoDecPriceFormat(cellProps.getValue())}</div>
            },
            {
                header: "Currency",
                accessorKey: "currency",
                size: 65
            },
            {
                header: "Local value",
                accessorKey: "localExposure",
                cell: (cellProps) => <div style={{ textAlign: "right" }}>{twoDecPriceFormat(cellProps.getValue())}</div>,
                aggregatedCell: () => null
            },
            {
                header: "Price date",
                accessorKey: "valuationDate",
                size: 90
            },
            {
                header: "FX rate",
                accessorKey: "fxRate",
                cell: (cellProps) => <div style={{ textAlign: "right" }}>{priceFormat(cellProps.getValue())}</div>,
                aggregatedCell: () => null,
                size: 85
            }
        ],
        [selectedClient]
    );

    useEffect(() => {
        //Only to make sure queryArg in Url when initializing the page
        if (
            date &&
            !queryArgs.date &&
            tabId &&
            tabId === "positions" &&
            (!previousClientDashName || (previousClientDashName && previousTabId !== tabId))
        ) {
            pushQueryArgs({ date });
        }
        if (dataClients) {
            const dashNameToClient: Record<string, any> = {};
            dataClients.clients.forEach((client) => {
                const dashName = client.name.replace(/ /g, "-");
                dashNameToClient[dashName] = client;
            });

            if (!clientDashName && selectedClient) {
                setSelectedClient(null);
            } else if (!selectedClient || clientDashName !== previousClientDashName) {
                const client = dashNameToClient[clientDashName];
                if (client) {
                    setSelectedClient({
                        value: client._id,
                        label: client.name,
                        name: client.name,
                        _id: client._id,
                        instruments: client.instruments,
                        accountingCurrency: client.accountingCurrency
                    });
                    const filter: TransactionFilterInput = { endDate: date, clientIds: [client._id], accountIds: null };

                    setFilter(filter);
                } else {
                    if (location.pathname !== "/positions" && !tabId) {
                        navigate("/positions");
                    }
                }
            }

            if (selectedClient && (selectedClient !== previousSelectedClient || date !== previousDate)) {
                const filter: TransactionFilterInput = { endDate: date, clientIds: [selectedClient._id], accountIds: null };
                setFilter(filter);
            }
        }
    }, [
        clientDashName,
        dataClients,
        date,
        location,
        navigate,
        previousClientDashName,
        previousDate,
        previousSelectedClient,
        previousTabId,
        pushQueryArgs,
        queryArgs,
        selectedClient,
        tabId
    ]);

    useEffect(() => {
        if (selectedClient && dataClients && dataClients.clients) {
            const client = dataClients.clients.find((x) => x._id == selectedClient._id);
            document.title = `Positions - ${client?.name}`;
        }
    }, [selectedClient, dataClients]);

    if (loadingClients) return <p>Loading clients</p>;
    if (errorClients) return <p>errorClients: {JSON.stringify(errorClients, null, 2)}</p>;
    if (loading) return <p>Loading</p>;
    if (error) return <p>error: {JSON.stringify(error, null, 2)}</p>;

    let options = [];

    dataClients.clients.forEach((client) => {
        if (client.instruments && client.instruments.length > 0) {
            options.push({ value: client._id, label: client.name, _id: client._id, instruments: client.instruments });
        }
    });
    options = sortBy(options, "label");

    let accessReportButton = false;
    if (data && userHaveAccessTo("Any", PermissionAssetEnum.Position, data.me.roles, PermissionTypeEnum.ReadWrite)) {
        accessReportButton = true;
    }

    const expandedPositions = selectedClient
        ? {
              ["clientName:" + selectedClient.label]: true
              /*["clientName:" + selectedClient.label + ">modelType:" + InstrumentModelTypeEnum.Bond]: true,
              ["clientName:" + selectedClient.label + ">modelType:" + InstrumentModelTypeEnum.CdsIndex]: true,
              ["clientName:" + selectedClient.label + ">modelType:" + InstrumentModelTypeEnum.Swap]: true,
              ["clientName:" + selectedClient.label + ">modelType:" + InstrumentModelTypeEnum.Balance]: true*/
          }
        : {};

    return (
        <div>
            <Page className="positionspage">
                <div>
                    {showClientSelector ? (
                        <Fragment>
                            <h4>Client</h4>
                            <div className="row d-flex">
                                <div className="col-12 col-sm-4">
                                    <Dropdown
                                        placeholder="None"
                                        defaultSelectedKey={selectedClient ? selectedClient._id : null}
                                        options={options.map((c) => {
                                            return { key: c._id, text: c.label };
                                        })}
                                        onChange={(e, option) => {
                                            const dashName = option.text.replace(/ /g, "-");
                                            navigate("/positions/" + dashName);
                                        }}
                                        calloutProps={{ doNotLayer: true, calloutMaxHeight: 300 }}
                                    />
                                </div>
                            </div>
                        </Fragment>
                    ) : null}
                    {processedData ? (
                        <Fragment>
                            <div className="row d-flex">
                                <div className="col-12 col-sm-4">
                                    <DateForm defaultDateString={date} dateName={"date"}></DateForm>
                                </div>
                            </div>
                            {accessReportButton && processedData && processedData.portfolioValue ? (
                                <DownloadDigiaFileButton processedData={processedData} selectedClient={selectedClient} date={date} />
                            ) : null}
                            <div
                                style={{ width: "20px", height: "20px", marginBottom: "1.0rem", marginTop: "1.0rem" }}
                                onClick={() => {
                                    if (processedData && processedData.allPositions && processedData.allPositions.length) {
                                        const currentRecords: sheetType = processedData.allPositions.map((i) => {
                                            delete i.__typename;
                                            return i;
                                        });
                                        exportToXlsx([currentRecords], "positions.xlsx");
                                    }
                                }}
                            >
                                <Svgs.Excel />
                            </div>

                            <div className="d-inline-flex flex-wrap mt-2">
                                <div className="me-3">
                                    <h4>Positions</h4>
                                    {processedData ? (
                                        <TableGrouper
                                            columns={columns}
                                            data={processedData.allPositions}
                                            expanded={expandedPositions}
                                            groupBy={["clientName", "modelType"]}
                                            compactMode={true}
                                            captionColumn="name"
                                        />
                                    ) : null}
                                </div>
                            </div>
                        </Fragment>
                    ) : null}
                </div>
            </Page>
        </div>
    );
};
