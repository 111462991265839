import React, { useState, useEffect } from "react";
import { gql, useQuery } from "urql";
import { sortBy } from "lodash";

import { downloadReport } from "./QuarterlyPositionsReport";
import { InstrumentModelTypeEnum } from "../types.generated";
import { usePrevious } from "../../../components/src/common/Utils";
import stableStringify from "json-stable-stringify";

const getTradingmanagerExternalAccounts = gql`
    query getTradingmanagerExternalAccounts($clientIds: [GraphQLObjectId!], $endDate: GraphQLDateString) {
        tradingmanager(
            filterZeroPositions: true
            filter: { endDate: $endDate, clientIds: $clientIds }
            excludeCollateral: true
            lookThrough: false
            groupPositionsBy: ExternalAccountId
        ) {
            name
            instrumentId
            instrument {
                type
                issuerProgramId
                issuerProgram {
                    category
                }
            }
            quantity
            currency
            exposure
            fxRate
            modelType
            externalAccountId
            externalAccountName
            externalAccountType
            externalAccount {
                custodian {
                    longName
                    name
                    legalEntityIdentifier
                }
            }
            baseCurrency
            valuationDate
        }
    }
`;

const otcTypes = {
    [InstrumentModelTypeEnum.Swap]: true,
    [InstrumentModelTypeEnum.PortfolioSwap]: true,
    [InstrumentModelTypeEnum.Swaption]: true,
    [InstrumentModelTypeEnum.FxSwap]: true,
    [InstrumentModelTypeEnum.GenericSwap]: true,
    [InstrumentModelTypeEnum.GenericFxSwap]: true,
    [InstrumentModelTypeEnum.EquityBasketOption]: true,
    [InstrumentModelTypeEnum.EquityUnitOption]: true,
    [InstrumentModelTypeEnum.CdsIndex]: true
};

const types = {
    Bond: { instrumentType: "OOS", market: "RM" },
    BondEuCovered: { instrumentType: "SO", market: "RM" },
    BondGovernment: { instrumentType: "OSK", market: "RM" },
    Stock: { instrumentType: "AK", market: "RM" },
    Swap: { instrumentType: "SW", market: "" },
    PortfolioSwap: { instrumentType: "SW", market: "" },
    Swaption: { instrumentType: "OD", market: "" },
    FxSwap: { instrumentType: "OD", market: "" },
    CdsIndex: { instrumentType: "OD", market: "" }
};

export function DownloadDigiaFileButton({
    processedData,
    selectedClient,
    date
}: {
    processedData: { portfolioValue: number; allPositions: any[] };
    selectedClient: {
        value: string;
        label: string;
        name: string;
        _id: string;
        instruments: any[];
        accountingCurrency: string;
    };
    date: string;
}): React.ReactElement {
    const [downloading, setDownloading] = useState<boolean>(false);

    const [{ error: queryError, data: positionsGroupedData }] = useQuery({
        query: getTradingmanagerExternalAccounts,
        variables: {
            filter: {
                clientIds: [selectedClient._id],
                endDate: date
            }
        },
        pause: !downloading,
        requestPolicy: "cache-and-network"
    });
    const previousPositionsGroupedData = usePrevious(positionsGroupedData);

    useEffect(() => {
        if (positionsGroupedData && stableStringify(positionsGroupedData) !== stableStringify(previousPositionsGroupedData)) {
            if (
                processedData &&
                processedData.allPositions.length > 0 &&
                positionsGroupedData !== null &&
                typeof positionsGroupedData !== "undefined"
            ) {
                let positions = processedData.allPositions.filter((position) => position.isCashAccount === false);
                positions = sortBy(positions, "name");
                let bankAccounts = processedData.allPositions.filter((position) => position.isCashAccount === true);
                bankAccounts = sortBy(bankAccounts, "name");
                downloadReport(
                    positions,
                    bankAccounts,
                    positionsGroupedData.tradingmanager,
                    processedData.portfolioValue,
                    selectedClient as any,
                    date,
                    types,
                    otcTypes
                );
                setDownloading(false);
            }
        }
    }, [date, positionsGroupedData, previousPositionsGroupedData, processedData, selectedClient]);

    if (queryError) return <div>{queryError.message}</div>;

    return (
        <button
            type="submit"
            onClick={async () => {
                setDownloading(true);
            }}
            className={`btn btn-sm float-end ${downloading ? "btn-danger" : "btn-primary"}`}
            disabled={downloading}
        >
            {downloading ? "Downloading..." : "Quarterly report (Digia)"}
        </button>
    );
}
