import React from "react";
import { useQueryState } from "../../../components/src/use-query-state";

export function TestPage(): React.ReactElement {
    const [number, setNumber] = useQueryState("number", 1);
    const [number2, setNumber2] = useQueryState("number2", 1);

    return (
        <div className="container">
            <div className="">{number}</div>
            <button
                type="submit"
                onClick={async () => {
                    setNumber(parseFloat(number.toString()) + 1);
                }}
                className={`btn btn-sm float-end btn-primary`}
                disabled={false}
            >
                {"set number"}
            </button>
            <div className="">{number2}</div>
            <button
                type="submit"
                onClick={async () => {
                    setNumber2(parseFloat(number2.toString()) + 1);
                }}
                className={`btn btn-sm float-end btn-primary`}
                disabled={false}
            >
                {"set number2"}
            </button>
        </div>
    );
}
