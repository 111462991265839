import React, { Fragment } from "react";

import { emptyObjectId, twoDecPriceFormat } from "../../../../../../common/src";
import { Grid, Column } from "../../../../../../components/src";
import { AccountingRun, PartyType, TAccountChart, TradingManagerColumn } from "../../../../types.generated";
import { AccountingFormatter } from "../AccountingFormatter";
import { ClientOption } from "./ClientOption";

type FinancialStatementsPropType = {
    selectedAccountingRun: AccountingRun;
    accountingRuns: AccountingRun[];
    partyTypes: PartyType[];
    positions: TradingManagerColumn[];
    latestTAccountChart: TAccountChart;
    client: ClientOption;
};

export function ReconciliationTab({
    selectedAccountingRun,
    accountingRuns,
    partyTypes,
    positions,
    latestTAccountChart,
    client
}: FinancialStatementsPropType): React.ReactElement {
    const accounting = new AccountingFormatter(selectedAccountingRun, accountingRuns);
    const journalEntries = accounting.getAccountingJournalEntries(selectedAccountingRun, accountingRuns);
    const { instrumentBalanceGroupedByType } = accounting.getAccountingTransactionsGrouped(journalEntries, partyTypes, latestTAccountChart);

    const positionsByNameTest: Record<string, Partial<TradingManagerColumn>> = {};
    for (const position of positions) {
        if (!positionsByNameTest[position.name]) {
            positionsByNameTest[position.name] = {};
            positionsByNameTest[position.name].quantity = position.quantity;
            positionsByNameTest[position.name].exposure = position.exposure;
            positionsByNameTest[position.name].fxRate = position.fxRate;
            positionsByNameTest[position.name].valuationPrice = position.valuationPrice;
        } else {
            positionsByNameTest[position.name].quantity += position.quantity;
            positionsByNameTest[position.name].exposure += position.exposure;
        }
    }

    // Adding data from positions
    let totalPositionValue = 0;
    for (const instrumentBalance of instrumentBalanceGroupedByType) {
        if (positionsByNameTest[instrumentBalance.Name]) {
            const position = positionsByNameTest[instrumentBalance.Name];
            instrumentBalance.Quantity = position.quantity;
            instrumentBalance.PositionValue = position.exposure;
            instrumentBalance.FxRate = position.fxRate;
            instrumentBalance.Price = position.valuationPrice;
            totalPositionValue += position.exposure;
        }
    }

    instrumentBalanceGroupedByType[instrumentBalanceGroupedByType.length - 1].Quantity = 0;
    instrumentBalanceGroupedByType[instrumentBalanceGroupedByType.length - 1].PositionValue = totalPositionValue;
    instrumentBalanceGroupedByType[instrumentBalanceGroupedByType.length - 1].FxRate = 0;
    instrumentBalanceGroupedByType[instrumentBalanceGroupedByType.length - 1].Price = 0;

    return (
        <Fragment>
            {client._id !== emptyObjectId ? (
                <div>
                    <Grid data={instrumentBalanceGroupedByType ? instrumentBalanceGroupedByType : []} sortable tableClassName="table-xs">
                        <Column className="grid-column-sticky nowrap" field="Name"></Column>
                        <Column
                            field="OpeningBalance"
                            title="Opening balance"
                            format={(number) => {
                                if (number) {
                                    return twoDecPriceFormat(number);
                                } else {
                                    return twoDecPriceFormat(0);
                                }
                            }}
                            className="nowrap right"
                        ></Column>
                        <Column
                            field="InitialCost"
                            title="Initial cost"
                            format={(number) => {
                                if (number) {
                                    return twoDecPriceFormat(number);
                                } else {
                                    return twoDecPriceFormat(0);
                                }
                            }}
                            className="nowrap right"
                        ></Column>
                        <Column
                            field="ValueChange"
                            title="Value change"
                            format={(number) => {
                                if (number) {
                                    return twoDecPriceFormat(number);
                                } else {
                                    return twoDecPriceFormat(0);
                                }
                            }}
                            className="nowrap right"
                        ></Column>
                        <Column
                            field="ShareCapital"
                            title="Share capital"
                            format={(number) => {
                                if (number) {
                                    return twoDecPriceFormat(number);
                                } else {
                                    return twoDecPriceFormat(0);
                                }
                            }}
                            className="nowrap right"
                        ></Column>
                        <Column
                            field="DividendPaid"
                            title="Dividend paid"
                            format={(number) => {
                                if (number) {
                                    return twoDecPriceFormat(number);
                                } else {
                                    return twoDecPriceFormat(0);
                                }
                            }}
                            className="nowrap right"
                        ></Column>
                        <Column
                            field="Balance"
                            format={(number, item) => {
                                let balance = 0;
                                if (item.OpeningBalance) {
                                    balance += item.OpeningBalance;
                                }
                                if (item.InitialCost) {
                                    balance += item.InitialCost;
                                }
                                if (item.ValueChange) {
                                    balance += item.ValueChange;
                                }
                                if (item.DividendPaid) {
                                    balance += item.DividendPaid;
                                }
                                return twoDecPriceFormat(balance);
                            }}
                            className="nowrap right"
                        ></Column>
                        <Column
                            field="Unrealized"
                            format={(number) => {
                                if (number) {
                                    return twoDecPriceFormat(number);
                                } else {
                                    return twoDecPriceFormat(0);
                                }
                            }}
                            className="nowrap right"
                        ></Column>
                        <Column
                            field="UnrealizedFx"
                            title="Unrealized fx"
                            format={(number) => {
                                if (number) {
                                    return twoDecPriceFormat(number);
                                } else {
                                    return twoDecPriceFormat(0);
                                }
                            }}
                            className="nowrap right"
                        ></Column>
                        <Column
                            field="SumUnrealized"
                            title="Sum unrealized"
                            format={(number) => {
                                if (number) {
                                    return twoDecPriceFormat(number);
                                } else {
                                    return twoDecPriceFormat(0);
                                }
                            }}
                            className="nowrap right"
                        ></Column>
                        <Column
                            field="Realized"
                            format={(number) => {
                                if (number) {
                                    return twoDecPriceFormat(number);
                                } else {
                                    return twoDecPriceFormat(0);
                                }
                            }}
                            className="nowrap right"
                        ></Column>
                        <Column
                            field="RealizedFx"
                            title="Realized fx"
                            format={(number) => {
                                if (number) {
                                    return twoDecPriceFormat(number);
                                } else {
                                    return twoDecPriceFormat(0);
                                }
                            }}
                            className="nowrap right"
                        ></Column>
                        <Column
                            field="SumRealized"
                            title="Sum realized"
                            format={(number) => {
                                if (number) {
                                    return twoDecPriceFormat(number);
                                } else {
                                    return twoDecPriceFormat(0);
                                }
                            }}
                            className="nowrap right"
                        ></Column>
                        <Column
                            field="ManagementFee"
                            title="Management fee"
                            format={(number) => {
                                if (number) {
                                    return twoDecPriceFormat(number);
                                } else {
                                    return twoDecPriceFormat(0);
                                }
                            }}
                            className="nowrap right"
                        ></Column>
                        <Column
                            field="CustodyFee"
                            title="Custody fee"
                            format={(number) => {
                                if (number) {
                                    return twoDecPriceFormat(number);
                                } else {
                                    return twoDecPriceFormat(0);
                                }
                            }}
                            className="nowrap right"
                        ></Column>
                        <Column
                            field="Rebate"
                            format={(number) => {
                                if (number) {
                                    return twoDecPriceFormat(number);
                                } else {
                                    return twoDecPriceFormat(0);
                                }
                            }}
                            className="nowrap right"
                        ></Column>
                        <Column
                            field="Commission"
                            format={(number) => {
                                if (number) {
                                    return twoDecPriceFormat(number);
                                } else {
                                    return twoDecPriceFormat(0);
                                }
                            }}
                            className="nowrap right"
                        ></Column>
                        <Column
                            field="StampDuty"
                            title="Stamp duty"
                            format={(number) => {
                                if (number) {
                                    return twoDecPriceFormat(number);
                                } else {
                                    return twoDecPriceFormat(0);
                                }
                            }}
                            className="nowrap right"
                        ></Column>
                        <Column
                            field="TaxRestitution"
                            title="Tax restitution"
                            format={(number) => {
                                if (number) {
                                    return twoDecPriceFormat(number);
                                } else {
                                    return twoDecPriceFormat(0);
                                }
                            }}
                            className="nowrap right"
                        ></Column>
                        <Column
                            field="ForeignTax"
                            title="Foreign tax"
                            format={(number) => {
                                if (number) {
                                    return twoDecPriceFormat(number);
                                } else {
                                    return twoDecPriceFormat(0);
                                }
                            }}
                            className="nowrap right"
                        ></Column>
                        <Column
                            field="Tax"
                            format={(number) => {
                                if (number) {
                                    return twoDecPriceFormat(number);
                                } else {
                                    return twoDecPriceFormat(0);
                                }
                            }}
                            className="nowrap right"
                        ></Column>
                        <Column
                            field="AccruedInterest"
                            title="Accrued interest"
                            format={(number) => {
                                if (number) {
                                    return twoDecPriceFormat(number);
                                } else {
                                    return twoDecPriceFormat(0);
                                }
                            }}
                            className="nowrap right"
                        ></Column>
                        <Column
                            field="Dividend"
                            format={(number) => {
                                if (number) {
                                    return twoDecPriceFormat(number);
                                } else {
                                    return twoDecPriceFormat(0);
                                }
                            }}
                            className="nowrap right"
                        ></Column>
                        <Column
                            field="Fee"
                            format={(number) => {
                                if (number) {
                                    return twoDecPriceFormat(number);
                                } else {
                                    return twoDecPriceFormat(0);
                                }
                            }}
                            className="nowrap right"
                        ></Column>
                        <Column
                            field="Interest"
                            format={(number) => {
                                if (number) {
                                    return twoDecPriceFormat(number);
                                } else {
                                    return twoDecPriceFormat(0);
                                }
                            }}
                            className="nowrap right"
                        ></Column>
                        <Column
                            field="Rounding"
                            format={(number) => {
                                if (number) {
                                    return twoDecPriceFormat(number);
                                } else {
                                    return twoDecPriceFormat(0);
                                }
                            }}
                            className="nowrap right"
                        ></Column>
                        <Column
                            field="SumIncomeFees"
                            title="Sum income fees"
                            format={(number) => {
                                if (number) {
                                    return twoDecPriceFormat(number);
                                } else {
                                    return twoDecPriceFormat(0);
                                }
                            }}
                            className="nowrap right"
                        ></Column>
                        <Column
                            field="SumIncome"
                            title="Sum income"
                            format={(number) => {
                                if (number) {
                                    return twoDecPriceFormat(number);
                                } else {
                                    return twoDecPriceFormat(0);
                                }
                            }}
                            className="nowrap right"
                        ></Column>
                        <Column
                            field="Quantity"
                            format={(number) => {
                                if (number) {
                                    return twoDecPriceFormat(number);
                                } else {
                                    return twoDecPriceFormat(0);
                                }
                            }}
                            className="nowrap right"
                        ></Column>
                        <Column
                            field="PositionValue"
                            title="Position value"
                            format={(number) => {
                                if (number) {
                                    return twoDecPriceFormat(number);
                                } else {
                                    return twoDecPriceFormat(0);
                                }
                            }}
                            className="nowrap right"
                        ></Column>
                        <Column
                            field="Price"
                            format={(number) => {
                                if (number) {
                                    return twoDecPriceFormat(number);
                                } else {
                                    return twoDecPriceFormat(0);
                                }
                            }}
                            className="nowrap right"
                        ></Column>
                        <Column
                            field="FxRate"
                            title="Fx rate"
                            format={(number) => {
                                if (number) {
                                    return twoDecPriceFormat(number);
                                } else {
                                    return twoDecPriceFormat(0);
                                }
                            }}
                            className="nowrap right"
                        ></Column>
                    </Grid>
                </div>
            ) : null}
        </Fragment>
    );
}
