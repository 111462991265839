import React, { Fragment, useState, useEffect, ReactElement } from "react";
import { sortBy, cloneDeep } from "lodash";
import { gql, useQuery, useMutation } from "urql";
import { Dropdown } from "react-bootstrap";
import save from "save-file";
import { Formik, Form } from "formik";
import stableStringify from "json-stable-stringify";
import { Link } from "react-router-dom";
import { useQueryState } from "../../../components/src/use-query-state";

import { Svgs, Grid, Column } from "../../../components/src";
import { SelectField, DateField, Checkbox } from "../../../components/src/form";
import { usePrevious } from "../../../components/src/common/Utils";

import { YesNoModal } from "../components/YesNoModal";
import { dateFormater, serializeSwedenDate } from "../components/dateFormater";
import { ReportStatusEnum } from "../types.generated";

const GET_REPORTS = gql`
    query reports($clientId: GraphQLObjectId!, $type: String, $date: GraphQLDateString, $statusIn: [ReportStatusEnum!]) {
        reports(clientId: $clientId, type: $type, date: $date, statusIn: $statusIn) {
            _id
            clientId
            clientName
            sourceId
            sourceName
            type
            name
            date
            reportId
            createTimestamp
            status
            updateUserId
            updateUserInfo {
                name
            }
        }

        reportTypes(clientIds: [$clientId])
    }
`;

const GET_FILE_REPORT = gql`
    query report($_id: GraphQLObjectId!) {
        report(_id: $_id) {
            _id
            clientId
            clientName
            sourceId
            sourceName
            type
            name
            date
            reportId
            createTimestamp
            data
            status
            base64
        }
    }
`;

const GET_CLIENTS = gql`
    query {
        clients: parties(filter: { typeIn: [Fund, Client] }) {
            _id
            name
        }
    }
`;

const DELETE_REPORT = gql`
    mutation deleteReport($_id: GraphQLObjectId!) {
        deleteReport(_id: $_id)
    }
`;

export const ReportsPage = (): ReactElement => {
    const [clientId, setClientId] = useQueryState("clientId", "000000000000000000000000");
    const [date, setDate] = useQueryState("date", serializeSwedenDate(new Date()));
    const [type, setType] = useQueryState("type", "All");
    const [showDeleted, setShowDeleted] = useQueryState("deleted", "false");

    const [clients, setClients] = useState([]);
    const [types, setTypes] = useState(["All"]);
    const [modal, setModal] = useState({ showModal: false, payload: null });
    const [variables, setVariables] = useState<{ _id: string }>(null);

    //const { loading: loadingClients, error: errorClients, data: dataClients } = useQuery(GET_CLIENTS);

    const [{ fetching: loadingClients, data: dataClients, error: errorClients }] = useQuery({
        query: GET_CLIENTS
    });

    const statusIn =
        showDeleted === "true"
            ? [ReportStatusEnum.Active, ReportStatusEnum.Deleted, ReportStatusEnum.Locked]
            : [ReportStatusEnum.Active, ReportStatusEnum.Locked];

    const [{ fetching: loading, data, error }, refetch] = useQuery({
        query: GET_REPORTS,
        variables: { clientId, type: type === "All" ? null : type, date: type === "All" ? date : null, statusIn },
        pause: clientId === "000000000000000000000000"
    });
    const previousData = usePrevious(data);

    const [{ data: fileData }] = useQuery({
        query: GET_FILE_REPORT,
        variables: variables,
        pause: variables ? false : true
    });
    const previousFileData = usePrevious(fileData);

    const [_, deleteReport] = useMutation(DELETE_REPORT);

    useEffect(() => {
        if (data && stableStringify(data) !== stableStringify(previousData)) {
            let reportTypes = [];
            // update reportTypes with 'All'/client
            if (data.reportTypes || data.reportTypes.length === 0) {
                reportTypes = data.reportTypes.map((r) => {
                    return { value: r, name: r, key: r };
                });
            }
            reportTypes.push({ value: "All", name: "All", key: "All" });
            setTypes(reportTypes);
        }
    }, [data, previousData]);

    useEffect(() => {
        if (fileData && stableStringify(fileData) !== stableStringify(previousFileData)) {
            const dataURI = "data:" + fileData.report.mimeType + ";base64," + fileData.report.base64;
            save(dataURI, fileData.report.name);
        }
    }, [fileData, previousFileData]);

    // populate 'clients' & select first one
    useEffect(() => {
        if (dataClients) {
            let allClients = dataClients.clients.map((c) => {
                return { value: c.name, name: c.name, _id: c._id, key: c._id };
            });
            allClients = sortBy(allClients, "name");
            setClients(allClients);
        }
    }, [dataClients]);

    // select first client if needed
    useEffect(() => {
        if (clientId === "000000000000000000000000" && clients.length > 0) {
            setClientId(clients[0].key);
        }
    }, [clients, clientId, setClientId]);

    if (loadingClients) return <p>Loading clients</p>;
    if (errorClients) return <p>errorClients: {JSON.stringify(errorClients, null, 2)}</p>;
    if (loading) return <p>Loading reports</p>;
    if (error) return <p>errorReports: {JSON.stringify(error, null, 2)}</p>;

    // populate 'reports' data
    const reports =
        data && data.reports
            ? data.reports.map((d, i) => {
                  const report = cloneDeep(d);
                  if (report.reportId) {
                      report.download = (
                          <div key={i} className="grid-attachment">
                              <Svgs.Attachment title={d.name} onClick={() => setVariables({ _id: d._id })} />
                          </div>
                      );
                  } else {
                      report.download = null;
                  }
                  return report;
              })
            : [];

    return (
        <div>
            {modal.showModal ? (
                <YesNoModal
                    warningText={"Are you sure you want to delete report " + modal.payload.name + "?"}
                    modal={{
                        showModal: modal.showModal,
                        payload: modal.payload
                    }}
                    setModal={setModal}
                    onYes={() => {
                        deleteReport({
                            _id: modal.payload._id
                        }).then(() => {
                            refetch();
                        });
                    }}
                />
            ) : null}

            <h4>Client</h4>
            <Formik
                enableReinitialize={true}
                initialValues={{ clientId, date, type }}
                validate={(validateFormData) => {
                    // type
                    if (validateFormData.type !== type) {
                        setType(validateFormData.type);
                    }
                    // date
                    const sweDate = serializeSwedenDate(validateFormData.date);
                    if (sweDate !== date) {
                        setDate(sweDate);
                        setType("All");
                    }
                    // clientId
                    if (validateFormData.clientId !== clientId) {
                        setClientId(validateFormData.clientId);
                        setDate(serializeSwedenDate(new Date()));
                        setType("All");
                    }

                    const errors = {};
                    return Object.keys(errors).length > 0 ? errors : {};
                }}
                onSubmit={null}
            >
                {({ isSubmitting }) => (
                    <Fragment>
                        <Form autoComplete="off">
                            <div className="form">
                                <div className="d-flex flex-row pt-2">
                                    <SelectField className="" name="clientId" label="Client" options={clients} disabled={isSubmitting} />
                                    <DateField className="ps-4" name="date" label="Date" disabled={isSubmitting || type !== "All"} />
                                    <SelectField className="ps-4" name="type" label="Type" options={types} disabled={isSubmitting} />
                                    <div className="ps-4 form-group">
                                        <div>&nbsp;</div>
                                        <label>
                                            <Checkbox
                                                id="chkShowDeleted"
                                                className="mt-2"
                                                checked={showDeleted === "true" ? true : false}
                                                disabled={isSubmitting}
                                                onChange={(e) => {
                                                    if (e.target.checked) {
                                                        setShowDeleted("true");
                                                    } else {
                                                        setShowDeleted("false");
                                                    }
                                                }}
                                            />
                                            <span className="ps-1">Show deleted reports</span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </Form>
                    </Fragment>
                )}
            </Formik>

            <div className="mt-4">
                {reports.length > 0 ? (
                    <Grid header="Reports" data={reports} sortable tableClassName="table-xs">
                        <Column
                            field="_id"
                            title="ID"
                            format={(value, item) => {
                                return item.reportId ? value : <Link to={"/report/" + item._id}>{value}</Link>;
                            }}
                        />
                        <Column field="download" title="Download" />
                        <Column field="name" title="Name" className="grid-column-sticky" />
                        <Column
                            field="clientName"
                            title="Client"
                            format={(value, item) => {
                                return <Link to={"/parties/" + item.clientId}>{value}</Link>;
                            }}
                        />
                        <Column
                            field="sourceName"
                            title="Source"
                            format={(value, item) => {
                                return item.sourceId === null ? value : <Link to={"/report/" + item.sourceId}>{value}</Link>;
                            }}
                        />
                        <Column field="type" />
                        <Column field="date" />
                        <Column field="createTimestamp" title="Create timestamp" format={dateFormater} />
                        <Column field="status" title="Status" />
                        <Column
                            className="nowrap"
                            field="user"
                            title="Updated by"
                            format={(_value, item) => {
                                if (item.updateUserInfo && item.updateUserInfo.name) {
                                    return item.updateUserInfo.name;
                                } else {
                                    return "";
                                }
                            }}
                        />
                        <Column
                            className="right"
                            field="Transaction"
                            title=""
                            format={(d, item) => {
                                if (item.status !== "Deleted") {
                                    return (
                                        <Dropdown>
                                            <Dropdown.Toggle
                                                type="button"
                                                className="danger btn-block"
                                                data-dismiss="modal"
                                                aria-label="Close"
                                            >
                                                delete
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <Dropdown.Item
                                                    key={item._id + "_delete"}
                                                    onClick={() => {
                                                        setModal({ showModal: true, payload: { _id: item._id, name: item.name } });
                                                    }}
                                                >
                                                    Delete
                                                </Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    );
                                } else {
                                    return <div></div>;
                                }
                            }}
                        ></Column>
                    </Grid>
                ) : (
                    <div>No reports found.</div>
                )}
            </div>
        </div>
    );
};
