import { useCallback, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import stableStringify from "json-stable-stringify";

export function useQueryState<T>(key: string, defaultValue: T): [T, (newValue: T) => void] {
    const [searchParams, setSearchParams] = useSearchParams();

    const paramValue = searchParams.get(key);
    const [value, setInternalValue] = useState<T>((paramValue as T) ?? defaultValue);

    // If no value is set, set the default value and update the URL
    useEffect(() => {
        const newValue = searchParams.get(key) as any;
        //console.log(`useEffect ${key} = ${newValue}`);
        if (newValue === null || newValue === undefined || newValue === "") {
            //console.log(`useEffect setting default value ${defaultValue}`);
            setInternalValue(defaultValue);
            const newParams = new URLSearchParams(searchParams);
            if (defaultValue === null || defaultValue === undefined || defaultValue === "") {
                newParams.delete(key);
            } else {
                newParams.set(key, defaultValue as any);
            }
            setSearchParams(newParams, { replace: true });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchParams, key, defaultValue]); // we don't want setSearchParams to be part of the dependencies

    const setValue = useCallback(
        (newValue) => {
            //console.log(`setValue ${key} = "${newValue}"`);
            const newParams = new URLSearchParams(searchParams);
            const oldValue = searchParams.get(key);
            //console.log(`oldValue ${key} = ${oldValue}`);
            if (newValue === null || newValue === undefined || newValue === "") {
                //console.log(`deleting key ${key}`);
                newParams.delete(key);
                setSearchParams(newParams, { replace: true });
                setInternalValue(null);
            } else if (stableStringify(oldValue) !== stableStringify(newValue)) {
                //console.log(`setting new value ${newValue}`);
                newParams.set(key, newValue);
                setSearchParams(newParams, { replace: true });
                setInternalValue(newValue);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [key, searchParams] // we don't want setSearchParams to be part of the dependencies
    );

    return [value, setValue];
}
